import { faCopyright, faRss } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, createStyles, darken, makeStyles, Theme, Tooltip } from '@material-ui/core'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React from 'react'
import { formatDateTime } from '../../utils/datetime'
import ResponsiveContainer from './ResponsiveContainer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
      boxShadow: `0 5px 15px 0px ${darken(theme.palette.primary.dark, 0.3)} inset`,
      fontSize: theme.typography.caption.fontSize,
      padding: `${theme.spacing()} 0`,
    },
    container: {
      paddingTop: '4em',
      paddingBottom: '4em',
      display: 'flex',
      justifyContent: 'space-between',
      '& a': {
        color: theme.palette.secondary.light,
      },
      '& img': {
        alignSelf: 'flex-start',
      },
      '& svg': {
        alignSelf: 'center',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        justifyContent: 'flex-start',
      },
    },
    info: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: -theme.spacing(),
      '&>*': {
        marginTop: theme.spacing(),
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(),
      },
    },
    links: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: -theme.spacing(),
      '&>*': {
        marginTop: theme.spacing(),
      },
    },
    copyright: {
      display: 'flex',
      alignItems: 'baseline',
      marginLeft: -theme.spacing() * 1,
      '&>*': {
        marginLeft: theme.spacing() * 1,
      },
    },
    textWithImg: {
      display: 'inline-flex',
      alignItems: 'baseline',
      margin: '0 .5em',
    },
    follow: {
      '& > a': {
        marginLeft: theme.spacing(),
      },
    },
  })
)

export default function Footer() {
  const {
    site: {
      buildTime,
      siteMetadata: {
        version,
        author: { name },
      },
    },
    gatsby,
  } = useStaticQuery(graphql`
    {
      site {
        buildTime
        siteMetadata {
          version
          author {
            name
          }
        }
      }
      gatsby: file(absolutePath: { regex: "/gatsby-icon.png$/" }) {
        publicURL
      }
    }
  `)
  const classes = useStyles()
  return (
    <footer className={classes.root}>
      <ResponsiveContainer className={classes.container}>
        <div className={classes.info}>
          <span className={classes.copyright}>
            <FontAwesomeIcon icon={faCopyright} />
            <span>{new Date().getFullYear()}</span>
            <span>
              主题及交互设计由<Link to="/about">{name}</Link>原创
            </span>
          </span>
          <span>
            原创文章遵循
            <a
              href="https://creativecommons.org/licenses/by-sa/4.0/deed.zh"
              target="_blank"
              rel="noreferrer"
            >
              CC BY-SA 4.0
            </a>
            授权许可，转载请注明出处
          </span>
          <span>
            更新于 <em>{formatDateTime(buildTime)}</em>
          </span>
          <span>
            版本 <em>v{version}</em>&nbsp; 使用
            <a
              href="https://www.gatsbyjs.org"
              target="_blank"
              rel="noreferrer"
              className={classes.textWithImg}
            >
              <img src={gatsby.publicURL} width="16" />
              &nbsp;Gatsby
            </a>
            框架生成静态页面
          </span>
        </div>
        <div className={classes.links}>
          <Box display="flex" className={classes.follow}>
            <span>关注本站</span>
            <Tooltip title="RSS">
              <a href="/rss.xml" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faRss} />
              </a>
            </Tooltip>
          </Box>
        </div>
      </ResponsiveContainer>
    </footer>
  )
}
