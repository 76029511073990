import { Box, createStyles, makeStyles, Theme } from '@material-ui/core'
import { Link } from 'gatsby'
import React from 'react'
import { Navs } from '../../@data/navs'
import NavItem from './NavItem'
import ThemeSwitch from './ThemeSwitch'

interface Props {
  align: string
  navs: Navs
  loc: 'left' | 'right'
  location: Location
}

type PropsType = Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing() * -3,
      paddingBottom: '6px',
      '&[data-loc=left]': {
        paddingRight: theme.spacing() * 3,
      },
      '&[data-loc=right]': {
        paddingLeft: theme.spacing() * 3,
      },
    },
    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      margin: `0 ${theme.spacing() * 1.5}px`,
      '&:hover': {
        textDecoration: 'none !important',
      },
    },
  })
)

export default function HeaderNavBar({
  navs,
  align,
  className,
  location,
  loc,
  ...props
}: PropsType): JSX.Element {
  const classes = useStyles()
  const currentNav = navs.getCurrentNav(location)

  return (
    <Box
      display="flex"
      justifyContent={align}
      alignItems="flex-end"
      className={[classes.root, className].join(' ')}
      data-loc={loc}
      {...props}
    >
      {navs.map(nav => {
        return (
          <Link to={nav.path} key={nav.path} className={classes.item}>
            <NavItem nav={nav} kind="vertical" active={nav === currentNav} />
          </Link>
        )
      })}

      {loc === 'right' ? (
        <>
          <div style={{ flex: '1 1 auto' }} />
          <ThemeSwitch className={classes.item} />
        </>
      ) : null}
    </Box>
  )
}
