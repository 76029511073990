import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createStyles, makeStyles, Theme } from '@material-ui/core'
import React from 'react'
import { Nav } from '../../@data/navs'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    item: {
      transition: '.2s ease-out',
      '&:hover': {
        textDecoration: 'none !important',
        transform: 'scale(1.2)',
      },
      '& *': {
        color: theme.nav.normalColor,
        transition: 'color .5s ease-out',
      },
      '&.active *': {
        color: theme.nav.activeColor,
      },
      '&:hover *': {
        color: theme.nav.hoverColor,
      },
    },
    vertical: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginLeft: theme.spacing() * 3,
      '&.active': {
        transform: 'scale(1.1)',
      },

      '&>span': {
        marginTop: '.2em',
        fontSize: '.7rem',
      },
    },
    horizontal: {
      display: 'flex',
      alignItems: 'center',
      transformOrigin: 'left',
      padding: '0 1.5em 0 1em',
      '&>span': { marginLeft: '.5em' },
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      outline: 'none',
      '&>span': { marginLeft: '.5em', fontSize: '1.1em' },
    },
  })
)

interface Props {
  nav: Nav
  active: boolean
  kind: 'horizontal' | 'vertical' | 'title'
}

export default function NavItem(props: Props) {
  const classes = useStyles()
  let cls: string[]
  switch (props.kind) {
    case 'vertical':
      cls = [classes.item, classes.vertical]
      break
    case 'horizontal':
      cls = [classes.item, classes.horizontal]
      break
    default:
      cls = [classes.item, classes.title]
      break
  }
  return (
    <div className={[cls.join(' '), props.active ? 'active' : ''].join(' ')}>
      <FontAwesomeIcon icon={props.nav.icon} size="lg" />
      <span>{props.nav.name.locale}</span>
    </div>
  )
}
