import { darken, fade, Hidden, Theme, useMediaQuery } from '@material-ui/core'
import { CSSProperties, withStyles } from '@material-ui/styles'
import { graphql, Link, StaticQuery } from 'gatsby'
import React from 'react'
import { connect } from 'react-redux'
import navs, { Navs } from '../../@data/navs'
import { ReduxState, ThemeType } from '../../@data/redux/store'
import HeaderNavBar from './HeaderNavBar'
import HeaderNavMenu from './HeaderNavMenu'
import ResponsiveContainer from './ResponsiveContainer'

interface Props {
  classes: Record<string, string>
  smallerScreen: boolean
  location: Location
  theme: ThemeType
}
interface State {
  isSticky: boolean
}

const logoWidth = 160
const logoFullHeight = 160
const logoStickOffset = 16
const logoCollapseHeight = logoFullHeight * 0.5625 + logoStickOffset
const logoXsWidth = 110
const logoXsFullHeight = 110
const logoXsStickOffset = 3
const logoXsCollapseHeight = logoXsFullHeight * 0.54 + logoXsStickOffset
const stickyThreshold = (full: number, collapse: number) => 1 - (collapse - 10) / full

class Header extends React.Component<Props, State> {
  private _observer: IntersectionObserver | null = null
  private _ref: React.RefObject<any>
  private _threshold = 0
  constructor(props: Props) {
    super(props)
    this._ref = React.createRef()
    this.state = { isSticky: false }
  }
  componentDidMount() {
    this._observeIntersection()
  }
  componentWillUnmount() {
    this._unobserveIntersection()
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.smallerScreen !== prevProps.smallerScreen) {
      this._unobserveIntersection()
      this._observeIntersection()
    }
  }
  private _observeIntersection() {
    if (this._ref.current) {
      const { full, collapse } = this._getHeights()
      this._threshold = stickyThreshold(full, collapse)
      this._observer = new IntersectionObserver(this._onStickyStateChanged.bind(this), {
        threshold: [this._threshold],
      })
      if (this._observer) {
        this._observer.observe(this._ref.current)
      }
    }
  }
  private _unobserveIntersection() {
    this._observer?.unobserve(this._ref.current)
    this._observer?.disconnect()
    this._observer = null
  }
  private _onStickyStateChanged(entries: IntersectionObserverEntry[]) {
    if (entries.length > 0) {
      this.setState({ isSticky: entries[0].intersectionRatio < this._threshold })
    }
  }
  private _getHeights(): { full: number; collapse: number } {
    if (this.props.smallerScreen) {
      return { full: logoXsFullHeight, collapse: logoXsCollapseHeight }
    } else {
      return { full: logoFullHeight, collapse: logoCollapseHeight }
    }
  }
  render() {
    const { classes } = this.props
    const leftNavs = new Navs(...navs.slice(0, Math.ceil(navs.length / 2)))
    const rightNavs = new Navs(...navs.slice(leftNavs.length))
    const header = (site: any, lightLogo: any, darkLogo: any) => (
      <div
        ref={this._ref}
        className={[classes.base, this.state.isSticky ? 'sticky' : ''].join(' ')}
        id="topmost"
      >
        <ResponsiveContainer className={classes.container}>
          <Hidden smDown>
            <HeaderNavBar
              navs={leftNavs}
              align="flex-end"
              loc="left"
              location={this.props.location}
              className={classes.navBar}
            />
          </Hidden>
          <Link to="/" className={[classes.logo, this.state.isSticky ? 'sticky' : ''].join(' ')}>
            <img
              src={(this.props.theme === ThemeType.Dark ? darkLogo : lightLogo).publicURL}
              alt={site.siteMetadata.title}
              width={this.props.smallerScreen ? logoXsWidth : logoWidth}
            />
          </Link>
          <Hidden smDown>
            <HeaderNavBar
              navs={rightNavs}
              align="flex-start"
              loc="right"
              location={this.props.location}
              className={classes.navBar}
            />
          </Hidden>
          <Hidden mdUp>
            <HeaderNavMenu navs={navs} location={this.props.location} />
          </Hidden>
        </ResponsiveContainer>
      </div>
    )
    return (
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                title
                description
              }
            }
            lightLogo: file(relativePath: { eq: "logo-full-light.svg" }) {
              publicURL
            }
            darkLogo: file(relativePath: { eq: "logo-full-dark.svg" }) {
              publicURL
            }
          }
        `}
        render={({ site, lightLogo, darkLogo }) => header(site, lightLogo, darkLogo)}
      />
    )
  }
}

const shadowColor = (theme: Theme, opacity: number) =>
  darken(fade(theme.palette.secondary.dark, opacity), 0.3)
const headerStyle = (theme: Theme) =>
  ({
    base: {
      position: 'sticky',
      top: `-${logoCollapseHeight}px`,
      height: `${logoFullHeight}px`,
      zIndex: 1000,
      transition: '.3s ease-out',
      '&.sticky': {
        background: theme.header.background,
        boxShadow:
          theme.palette.type === 'light'
            ? `
        0 2px 5px -5px rgba(0,0,0,.6),
        0 5px 15px 0 ${shadowColor(theme, 0.2)}
        `
            : 'none',
      },
      borderBottom:
        theme.palette.type === 'light' ? 'none' : `1px solid ${theme.palette.primary.light}`,
      [theme.breakpoints.down('xs')]: {
        top: `-${logoXsCollapseHeight}px`,
        height: `${logoXsFullHeight}px`,
      },
    },
    container: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      [theme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
      },
    },
    logo: {
      display: 'flex',
      cursor: 'pointer',
      transition: `transform .5s cubic-bezier(0.24, -0.45, 0.67, -0.15);`,
      boxShadow: `
        0 1px 3px 0 ${shadowColor(theme, 0.4)},
        0 3px 5px 0 ${shadowColor(theme, 0.2)},
        0 5px 15px 0 ${shadowColor(theme, 0.13)}`,
      '&.sticky': {
        transform: `translateY(${logoStickOffset}px)`,
        transition: `transform .5s cubic-bezier(0.18, 0.89, 0.43, 1.99);`,
        boxShadow: `
        0 2px 5px -5px rgba(0,0,0,.6),
        0 5px 10px 0 ${shadowColor(theme, 0.4)},
        0 10px 25px 5px ${shadowColor(theme, 0.13)}`,
      },
      [theme.breakpoints.down('xs')]: {
        marginLeft: '-20px',
        '&.sticky': {
          transform: `translateY(${logoXsStickOffset}px)`,
        },
      },
    },
    navBar: {
      background: 'transparent',
      width: `calc((100vw - ${logoWidth}px) * 0.5)`,
    },
  } as Record<string, CSSProperties>)

function withSmallerScreen(Component: typeof Header, than: 'xs' | 'sm' | 'md' | 'lg' | 'xl') {
  return function Wrapped(props: any) {
    const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down(than))

    return <Component {...props} smallerScreen={matches} />
  }
}

export default connect((state: ReduxState) => ({ theme: state.theme }))(
  withStyles(headerStyle)(withSmallerScreen(Header, 'xs'))
)
