import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faCode, faMugHot, faUserAlien } from '@fortawesome/pro-duotone-svg-icons'

type Langs = 'zh'

type I18nStringType = Record<Langs, string>

class I18nString {
  constructor(readonly i18n: I18nStringType) {}

  get locale() {
    return this.i18n.zh
  }
}
export abstract class Nav {
  constructor(
    readonly key: string,
    readonly icon: IconDefinition,
    readonly name: I18nString,
    readonly description: I18nString
  ) {}
  abstract readonly path: string
  abstract isActive(pathname: string): boolean
}
class RootNav extends Nav {
  get path() {
    return `/${this.key}`
  }
  isActive(pathname: string) {
    return pathname.startsWith(this.path)
  }
}

const subBlogPrefixes = ['blog', 'category', 'tag']
class SubBlogNav extends Nav {
  get path() {
    return `/blog/${this.key}`
  }
  isActive(pathname: string) {
    for (const prefix of subBlogPrefixes) {
      const expect = `/${prefix}/${this.key}`
      if (pathname.startsWith(expect)) {
        return true
      }
    }
    return false
  }
}

export class Navs extends Array<Nav> {
  private readonly _map: Record<string, Nav>
  constructor(...items: Nav[]) {
    super(...items)
    this._map = {}
    items.forEach(item => (this._map[item.key] = item))
  }

  get(key: string) {
    return this._map[key]
  }

  getMostSimilarPath(location: Location) {
    const p = location.pathname
    const sub = this.find(nav => nav.isActive(p))
    return sub ? sub.path : '/'
  }
  getCurrentNav(location: Location) {
    return this.find(nav => nav.path === this.getMostSimilarPath(location))
  }
}

const navs = new Navs(
  new SubBlogNav(
    'dev',
    faCode,
    new I18nString({ zh: '开发手记' }),
    new I18nString({
      zh: `写代码过程中的思考、总结与心得，希望与您分享。
内容涉及前端、后端、游戏等方面的开发记录，以及我认为对提高生产效率有所裨益的技巧等。  
不定期更新，欢迎随时来看看。期待并感谢任何意见与指正。`,
    })
  ),
  new SubBlogNav(
    'bits',
    faMugHot,
    new I18nString({ zh: '零七八碎儿' }),
    new I18nString({
      zh: `杂感随笔、胡言乱语，想啥写啥大小事儿；兴趣爱好、奇闻轶事，没话找话三两篇儿。`,
    })
  ),
  new RootNav(
    'about',
    faUserAlien,
    new I18nString({ zh: '关于' }),
    new I18nString({ zh: `关于作者和网站的信息` })
  )
)

export default navs
