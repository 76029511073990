import { Container, createStyles, makeStyles, Theme } from '@material-ui/core'
import { CSSProperties } from '@material-ui/styles'
import React from 'react'

interface Props {
  className?: string
  style?: CSSProperties
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing() * 2,
        paddingRight: theme.spacing() * 2,
      },
    },
  })
)
export default function ResponsiveContainer(props: React.PropsWithChildren<Props>) {
  const classes = useStyles()
  return (
    <Container maxWidth="md" className={[classes.container, props.className].join(' ')}>
      {props.children}
    </Container>
  )
}
