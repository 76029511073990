import { faPortalEnter } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, MenuItem, Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { Navs } from '../../@data/navs'
import NavItem from './NavItem'
import ThemeSwitch from './ThemeSwitch'

interface Props {
  navs: Navs
  location: Location
}
interface State {
  anchorEl: Element | null
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuBar: {
      flex: '1 1 auto',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      marginLeft: theme.spacing() * 2,
      color: theme.nav.normalColor,
      fontSize: theme.typography.subtitle1.fontSize,
      paddingBottom: theme.spacing(),
    },
    title: {
      color: theme.nav.normalColor,
      '&:hover': {
        color: theme.nav.hoverColor,
      },
    },
    more: {
      display: 'flex',
      marginRight: '-1em',
      '&>*': {
        marginRight: '1em',
        fontSize: '1rem',
        color: theme.nav.normalColor,
      },
    },
  })
)

export default function HeaderNavMenu(props: Props) {
  const [state, setState] = useState<State>({ anchorEl: null })
  const classes = useStyles()
  const currentNav = props.navs.getCurrentNav(props.location)
  return (
    <React.Fragment>
      <div className={classes.menuBar}>
        <Link to={currentNav ? currentNav.path : '/'} className={classes.title} tabIndex={-1}>
          {currentNav ? <NavItem nav={currentNav} kind="title" active={false} /> : null}
        </Link>
        <div className={classes.more}>
          <div onClick={e => setState({ anchorEl: e.currentTarget })}>
            <FontAwesomeIcon icon={faPortalEnter} size="lg" />
          </div>
          <ThemeSwitch iconOnly />
        </div>
      </div>
      <Menu
        id="nav-menu"
        open={!!state.anchorEl}
        keepMounted
        anchorEl={state.anchorEl}
        onClose={() => setState({ anchorEl: null })}
      >
        {props.navs.map(nav => (
          <MenuItem key={nav.path} component={Link} to={nav.path} selected={nav === currentNav}>
            <NavItem nav={nav} kind="horizontal" active={nav === currentNav} />
          </MenuItem>
        ))}
      </Menu>
    </React.Fragment>
  )
}
