import { faMoon, faSun } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Theme } from '@material-ui/core'
import { createStyles, makeStyles } from '@material-ui/styles'
import React from 'react'
import { connect } from 'react-redux'
import { ReduxState, switchTheme, ThemeType } from '../../@data/redux/store'

interface Props {
  iconOnly?: boolean
  className?: string
  theme: ThemeType
  switchTheme: (theme: ThemeType) => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignSelf: 'flex-end',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      fontSize: '1rem',
      transition: '.2s ease-out',
      '&:hover': {
        textDecoration: 'none !important',
        transform: 'scale(1.2)',
      },
      '& *': {
        color: theme.nav.normalColor,
        transition: 'color .5s ease-out',
      },
      '&.active *': {
        color: theme.nav.activeColor,
      },
      '&:hover *': {
        color: theme.nav.hoverColor,
      },
      '&>span': {
        fontSize: '.7em',
        marginTop: '.2em',
      },
    },
  })
)

function ThemeSwitch(props: Props) {
  const classes = useStyles()
  return (
    <div
      className={[props.className, classes.root].join(' ')}
      onClick={() =>
        props.switchTheme(props.theme === ThemeType.Dark ? ThemeType.Light : ThemeType.Dark)
      }
    >
      <FontAwesomeIcon icon={props.theme === ThemeType.Dark ? faSun : faMoon} size="lg" />
      {props.iconOnly ? null : <span>切换主题</span>}
    </div>
  )
}
export default connect((state: ReduxState) => ({ theme: state.theme }), { switchTheme })(
  ThemeSwitch
)
