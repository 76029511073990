import { Box } from '@material-ui/core'
import React from 'react'
import Footer from './Footer'
import Header from './Header'
import ResponsiveContainer from './ResponsiveContainer'
import Root from './Root'
// import { rhythm, scale } from '../utils/typography'

declare const __PATH_PREFIX__: string

interface Props {
  location: Location
  title: string
  children?: React.ReactNode
}

const Layout = ({ children, location }: Props) => {
  return (
    <Root>
      <Header location={location}></Header>
      <ResponsiveContainer>
        <Box my={5}>{children}</Box>
      </ResponsiveContainer>
      <Footer></Footer>
    </Root>
  )
}

export default Layout
